/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext, useState } from "react";
import Image from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import tw, { css } from "twin.macro";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import * as T from "~components/styles/Typography.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";

const PortableText = ({ blocks }) => {
  const { device } = useContext(DocumentContext);

  const sanityConfig = { projectId: `ycuu4h2c`, dataset: `production` };

  const [serializers, setSerializers] = useState({
    types: {
      block: (props) => {
        switch (props.node.style) {
          case `h1`:
            return (
              <T.Heading inject={{ tw: tw`mb-8 ` }}>{props.children}</T.Heading>
            );

          case `h2`:
            return (
              <T.Heading font="2" inject={{ tw: tw`mb-8 ` }}>
                {props.children}
              </T.Heading>
            );

          case `h3`:
            return (
              <T.Heading font="3" inject={{ tw: tw`mb-8 ` }}>
                {props.children}
              </T.Heading>
            );

          case `h4`:
            return (
              <T.Heading font="4" inject={{ tw: tw`mb-8 ` }}>
                {props.children}
              </T.Heading>
            );

          case `normal`:
            return <T.Body inject={{ tw: tw`mb-8 ` }}>{props.children}</T.Body>;

          default:
            return <></>;
        }
      },
      altImage: (props) => {
        const fluidProps = getFluidGatsbyImage(
          props.node.asset._ref,
          { maxWidth: 800 },
          sanityConfig
        );
        return (
          <Image
            css={[tw`col-span-12 md:col-span-11 relative block`]}
            fluid={fluidProps}
            alt={props.node.altText}
          />
        );
      }
    }
  });

  //

  return (
    <BlockContent
      className="portable-text"
      blocks={blocks}
      serializers={serializers}
    />
  );
};

export default PortableText;
