/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import Image from "~components/Image.jsx";

const ImageArticleSection = ({
  data: { heading, body, image, imageAlign, imageSize }
}) => {
  const { isDesktop } = useContext(DocumentContext);
  //

  const imageJSX = <Image image={image} />;

  let imagePadding;

  switch (imageSize?.toLowerCase()) {
    case `s`:
    case `small`:
      imagePadding = `0 6vw`;
      break;

    case `l`:
    case `large`:
    default:
      imagePadding = `0`;
      break;
  }

  //

  let imageColumnStart;
  let textOrder;
  let textColumnStart;

  switch (imageAlign?.toLowerCase()) {
    case `left`:
      imageColumnStart = 1;
      textOrder = 1;
      textColumnStart = 7;
      break;

    case `right`:
    default:
      imageColumnStart = isDesktop() ? 8 : 5;
      textOrder = 0;
      textColumnStart = 1;
      break;
  }

  //

  return (
    <div css={[tw`relative md:py-16 py-8`]}>
      <Grid node="section" inject={{ tw: tw`items-center` }}>
        <div
          css={[
            css`
              order: ${isDesktop() ? textOrder : 0};
              grid-column-start: 1;
              grid-column: span 12 / span 12;

              @media screen and (min-width: 1025px) {
                grid-column: span 6 / span 6;
                grid-column-start: ${textColumnStart};
              }
            `
          ]}
        >
          <article
            css={[
              css`
                @media screen and (min-width: 1025px) {
                  max-width: 600px;
                }
              `
            ]}
          >
            <T.Heading
              font="2"
              level="2"
              inject={{ tw: tw`whitespace-pre-wrap mb-8` }}
            >
              {heading}
            </T.Heading>

            {isDesktop() && (
              <T.Body
                font="1"
                inject={{
                  css: `a { text-decoration: underline; }`,
                  tw: tw`whitespace-pre-wrap`
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: body }} />
              </T.Body>
            )}

            {!isDesktop() && (
              <>
                <figure
                  css={[
                    css`
                      padding: ${imagePadding};
                      width: 60vw;
                      margin-left: ${imageColumnStart > 1 ? `auto` : `0`};
                      padding: 0 0 2rem 0;
                      @media screen and (min-width: 769px) {
                        width: 50vw;
                      }
                    `
                  ]}
                >
                  {imageJSX}
                </figure>

                <T.Body
                  font="1"
                  inject={{
                    css: `a { text-decoration: underline; }`,
                    tw: tw`whitespace-pre-wrap`
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: body }} />
                </T.Body>
              </>
            )}
          </article>
        </div>

        {isDesktop() && (
          <figure
            css={[
              css`
                padding: ${imagePadding};

                grid-column-start: 1;
                grid-column: span 12 / span 12;

                @media screen and (min-width: 1025px) {
                  grid-column: span 5 / span 5;
                  grid-column-start: ${imageColumnStart};
                }
              `
            ]}
          >
            {imageJSX}
          </figure>
        )}
      </Grid>
    </div>
  );
};

export default ImageArticleSection;
