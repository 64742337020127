/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import Button from "~components/Button.jsx";
import Go from "~components/Go.jsx";

const TextSection = ({
  data: { backgroundColor, fontColor, button, body, heading, asH1 }
}) => {
  const { isDesktop } = useContext(DocumentContext);

  //

  return (
    <div
      css={[
        css`
          background-color: ${backgroundColor?.hex || theme`colors.white`};
          color: ${fontColor?.hex || theme`colors.black`};
        `,
        tw`relative z-10 pt-12 md:pt-36 pb-12 md:pb-32`
      ]}
    >
      <Grid node="section">
        <article
          css={[
            css`
              ${A.Keyframes(
                `appearDown`,
                `1s ${A.EASING_CUBIC} forwards`,
                `0.5s`
              )}

              padding-right: ${isDesktop() ? 5 : 0}vw;
            `,
            tw`col-span-12 md:col-span-11`
          ]}
        >
          {heading && (
            <T.Heading
              font="b2"
              level={asH1 ? `1` : `2`}
              inject={{
                css: `font-weight: 700;`,
                tw: tw`mb-2 whitespace-pre-wrap uppercase`
              }}
            >
              {heading}
            </T.Heading>
          )}

          <T.Body
            font={isDesktop() ? `h2` : `h3`}
            inject={{
              css: `a {text-decoration: underline}`,
              tw: tw`whitespace-pre-wrap`
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: body }} />
          </T.Body>

          {button?.buttonTarget && button?.buttonText && (
            <Go
              to={button.buttonTarget}
              newTab={button.buttonTarget.includes(`http`)}
            >
              <Button
                color="black"
                inject={{ tw: tw`h-10 mt-10 md:mt-16` }}
                text={button.buttonText}
                transparent
              />
            </Go>
          )}
        </article>
      </Grid>
    </div>
  );
};

export default TextSection;
