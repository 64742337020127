/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import Button from "~components/Button.jsx";
import Go from "~components/Go.jsx";
import Image from "~components/Image.jsx";

const ProductCard = ({ product }) => {
  // ===========================================================================
  // variables

  const availableSizes = [];

  if (product?.shopifyProduct?.variants?.[0]) {
    product.shopifyProduct.variants.forEach((variant) => {
      variant.selectedOptions.forEach((selectedOption) => {
        if (
          (selectedOption?.name.toLowerCase() === `configuration` ||
            selectedOption?.name.toLowerCase() === `size`) &&
          !availableSizes?.includes(selectedOption.value)
        ) {
          availableSizes.push(selectedOption.value);
        }
      });
    });
  }

  // ===========================================================================
  // render

  return (
    <article css={[tw`w-full h-full relative flex flex-col items-stretch`]}>
      <Go to={`/products/${product.handle}`} inject={{ tw: tw`relative` }}>
        <div
          css={[
            css`
              padding-bottom: 83.3333%;

              .gatsby-image-wrapper {
                transition: transform 0.3s ${A.EASING_CUBIC};
              }

              @media not all and (pointer: coarse) {
                &:hover {
                  .gatsby-image-wrapper {
                    transform: scale(1.025);
                  }
                }
              }
            `,
            tw`w-full relative block overflow-hidden`
          ]}
        >
          {product?.image?.asset?.fluid && (
            <figure tw="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
              <Image
                image={product.image}
                inject={{
                  tw: tw`w-full relative block object-cover`
                }}
              />
            </figure>
          )}
        </div>
      </Go>

      <div
        css={[
          css`
            flex: 1 0 auto;
          `,
          tw`relative flex flex-col justify-between`
        ]}
      >
        <div>
          <T.Body
            font="2"
            inject={{ css: `font-weight: 700;`, tw: tw`font-bold uppercase` }}
          >
            {product.title}
          </T.Body>

          <T.Body font="2" inject={{ tw: tw`mb-8 pr-12 uppercase` }}>
            {availableSizes.join(` / `)}
          </T.Body>
        </div>

        <Go to={`/products/${product.handle}`}>
          <Button
            color="black"
            inject={{ tw: tw`h-10 mb-1` }}
            text={`Shop ${product.title}`}
            transparent
          />
        </Go>
      </div>
    </article>
  );
};

export default ProductCard;
