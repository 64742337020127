/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import useSanityShopifyProducts from "~hooks/useSanityShopifyProducts.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import Button from "~components/Button.jsx";
import Go from "~components/Go.jsx";
import ProductCard from "~components/ProductCard.jsx";
import SwiperCarousel from "~components/SwiperCarousel.jsx";

const ProductCarousel = ({ data: { buttonLink, heading, products } }) => {
  // ===========================================================================
  // context / ref / state

  const { isDesktop, screen } = useContext(DocumentContext);

  const { allSanityShopifyProduct } = useSanityShopifyProducts();

  // ===========================================================================
  // variables

  if (!products?.[0]) {
    products = [];

    if (allSanityShopifyProduct?.edges?.[0]) {
      allSanityShopifyProduct.edges.forEach(({ node }, productIndex) => {
        if (isDesktop() || productIndex < 4) {
          products.push(node);
        }
      });
    }
  }

  // ===========================================================================
  // render

  return (
    <section
      css={[
        css`
          .swiper-container {
            @media screen and (min-width: 1025px) {
              overflow: visible !important;
            }
          }
        `,
        tw`w-screen relative pt-12 md:pt-24 pb-8 md:pb-24 overflow-x-hidden`
      ]}
    >
      {products?.[0] && (
        <>
          <Grid>
            <header tw="col-span-12 block mb-4 md:mb-8">
              <T.Heading
                inject={{ tw: tw`whitespace-pre-wrap` }}
                font={isDesktop() ? `2` : `3`}
                level="2"
              >
                {heading}
              </T.Heading>
            </header>
          </Grid>

          <SwiperCarousel
            inject={{ css: `max-width: 1600px`, tw: tw`mb-8 md:mb-12 px-6` }}
            options={{
              spaceBetween: 16,
              slidesPerView: isDesktop() ? 2 : 1
            }}
            nav={({ current, next, prev }) => (
              <>
                {screen && isDesktop() && (
                  <Grid>
                    <div css={[tw`col-span-6 relative`]}>
                      {buttonLink?.buttonTarget && (
                        <section css={[tw`w-full relative`]}>
                          <Go
                            to={buttonLink.buttonTarget}
                            newTab={buttonLink?.buttonTarget?.includes(`http`)}
                          >
                            <Button
                              color="black"
                              inject={{ tw: tw`h-10` }}
                              text={buttonLink.buttonText}
                            />
                          </Go>
                        </section>
                      )}
                    </div>

                    <div
                      css={[
                        tw`col-span-6 relative flex items-center justify-end`
                      ]}
                    >
                      <button
                        type="button"
                        css={[
                          css`
                            @media not all and (pointer: coarse) {
                              &:hover {
                                background: ${theme`colors.black`};

                                svg {
                                  polyline {
                                    stroke: ${theme`colors.white`};
                                  }
                                }
                              }
                            }
                          `,
                          tw`w-8 h-8 relative block rounded-full overflow-hidden bg-white`
                        ]}
                        onClick={prev}
                      >
                        <Icon.ArrowButtonLeft />
                      </button>

                      <button
                        type="button"
                        css={[
                          css`
                            @media not all and (pointer: coarse) {
                              &:hover {
                                background: ${theme`colors.black`};

                                svg {
                                  polyline {
                                    stroke: ${theme`colors.white`};
                                  }
                                }
                              }
                            }
                          `,
                          tw`w-8 h-8 relative block ml-2 rounded-full overflow-hidden bg-white`
                        ]}
                        onClick={next}
                      >
                        <Icon.ArrowButtonRight />
                      </button>
                    </div>
                  </Grid>
                )}

                {screen && !isDesktop() && (
                  <div
                    css={[tw`w-full relative flex items-center justify-center`]}
                  >
                    <ul css={[tw`flex`]}>
                      {products.map((_, productIndex) => {
                        const key = `gallery-swiper-bullet-${productIndex}`;

                        return (
                          <li
                            key={key}
                            css={[
                              css`
                                background: ${current === productIndex
                                  ? theme`colors.black`
                                  : `transparent`};
                              `,
                              tw`w-3 h-3 relative block rounded-full mx-1 border-black border`
                            ]}
                          />
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            )}
            slides={products.map((product) => (
              <li
                key={product.handle}
                css={[
                  css`
                    flex 1 1 auto;
                  `,
                  tw`relative`
                ]}
              >
                <ProductCard product={product} />
              </li>
            ))}
          />
        </>
      )}
    </section>
  );
};

export default ProductCarousel;
