/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import tw from "twin.macro";
import Image from "~components/Image.jsx";

const ImageSection = ({ data: { image } }) => (
  <>
    {image?.asset?.fluid && (
      <figure css={[tw`w-full relative`]}>
        <Image
          inject={{ tw: tw`w-full h-full relative block` }}
          image={image}
        />
      </figure>
    )}
  </>
);

export default ImageSection;
