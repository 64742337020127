/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as A from "~components/styles/Animations.jsx";
import PortableText from "~components/PortableText.jsx";

const OpenTextSection = ({ data: _rawBody }) => {
  const { isDesktop } = useContext(DocumentContext);

  //

  return (
    <>
      <Grid>
        <article
          css={[
            css`
              ${A.Keyframes(
                `appearDown`,
                `1s ${A.EASING_CUBIC} forwards`,
                `0.5s`
              )}
            `,
            tw`col-span-12 md:col-span-11 pt-10 md:pt-16 pb-16`
          ]}
        >
          <PortableText blocks={_rawBody._rawBody} />
        </article>
      </Grid>
    </>
  );
};

export default OpenTextSection;
