/* eslint-disable react/jsx-props-no-spreading */
// https://swiperjs.com/react

/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import tw, { css } from "twin.macro";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { DocumentContext } from "~context/DocumentContext.jsx";

import "swiper/swiper-bundle.css";

const SwiperCarousel = ({
  effect,
  goIndex,
  id,
  inject,
  loop,
  options,
  nav,
  slides
}) => {
  SwiperCore.use([Autoplay, EffectFade]);

  // ===========================================================================
  // context / ref / state

  const { isDesktop, screen } = useContext(DocumentContext);

  const swiper = useRef(null);

  const [current, setCurrent] = useState(1);

  // ===========================================================================
  // methods

  const go = (index) => {
    if (swiper?.current?.swiper?.slideNext) {
      swiper.current.swiper.slideTo(index);
    }
  };

  const next = () => {
    if (swiper?.current?.swiper?.slideNext) {
      swiper.current.swiper.slideNext();
    }
  };

  const prev = () => {
    if (swiper?.current?.swiper?.slidePrev) {
      swiper.current.swiper.slidePrev();
    }
  };

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (goIndex === null || !swiper?.current?.swiper?.slideTo) {
      return;
    }

    swiper.current.swiper.slideTo(goIndex + 1);
  }, [goIndex]);

  // ===========================================================================
  // render

  if (!screen) {
    return <></>;
  }

  return (
    <>
      {(isDesktop() && effect && (
        <Swiper
          id={id}
          ref={swiper}
          effect={effect}
          css={[
            css`
              ${inject?.css || ``}
            `,
            inject?.tw || tw``
          ]}
          loop={loop}
          onSlideChange={({ realIndex }) => {
            setCurrent(realIndex);
          }}
          {...options}
        >
          {slides.map((slide) => (
            <SwiperSlide>{slide}</SwiperSlide>
          ))}
        </Swiper>
      )) || (
        <Swiper
          id={id}
          ref={swiper}
          css={[
            css`
              ${inject?.css || ``}
            `,
            inject?.tw || tw``
          ]}
          loop={loop}
          onSlideChange={({ realIndex }) => {
            setCurrent(realIndex);
          }}
          {...options}
        >
          {slides.map((slide) => (
            <SwiperSlide>{slide}</SwiperSlide>
          ))}
        </Swiper>
      )}

      {nav &&
        nav({
          current,
          go,
          next,
          prev
        })}
    </>
  );
};

SwiperCarousel.defaultProps = {
  id: `default-swiper`,
  effect: null,
  goIndex: null,
  inject: null,
  loop: true,
  options: {
    spaceBetween: 0,
    slidesPerView: 1
  },
  slides: null
};
SwiperCarousel.propTypes = {
  id: PropTypes.string,
  effect: PropTypes.string,
  goIndex: PropTypes.number,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  loop: PropTypes.bool,
  options: PropTypes.shape({}),
  slides: PropTypes.arrayOf(PropTypes.shape({}))
};

export default SwiperCarousel;
