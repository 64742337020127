/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import Grid from "~components/styles/Grid.jsx";
import Image from "~components/Image.jsx";
import SwiperCarousel from "~components/SwiperCarousel.jsx";

const ImageCarouselSection = ({ data: { autoplay, images } }) => {
  // ===========================================================================
  // context / ref / state

  const { isDesktop, screen } = useContext(DocumentContext);

  // ===========================================================================
  // render

  let options = {};

  if (autoplay && parseInt(autoplay) > 0) {
    options = {
      autoplay: {
        delay: autoplay * 1000
      }
    };
  }

  return (
    <div css={[tw`w-screen relative block`]}>
      {images?.[0] && (
        <section
          css={[
            css`
              height: 100vw;

              @media screen and (min-width: 1025px) {
                height: 56.25vw;
              }

              @media screen and (min-width: 1601px) {
                height: 960px;
              }
            `,
            tw`w-full relative`
          ]}
        >
          <SwiperCarousel
            effect="fade"
            options={options}
            inject={{ tw: tw`w-full h-full relative` }}
            nav={({ current, next, prev }) => (
              <div
                css={[
                  tw`w-full absolute bottom-0 right-0 left-0 z-20 pb-8 md:pb-12`
                ]}
              >
                {screen && isDesktop() && (
                  <Grid>
                    <div
                      css={[
                        tw`col-span-12 relative flex items-center justify-end`
                      ]}
                    >
                      <button
                        type="button"
                        css={[
                          css`
                            @media not all and (pointer: coarse) {
                              &:hover {
                                background: ${theme`colors.black`};

                                svg {
                                  polyline {
                                    stroke: ${theme`colors.white`};
                                  }
                                }
                              }
                            }
                          `,
                          tw`w-8 h-8 relative block rounded-full overflow-hidden`
                        ]}
                        onClick={prev}
                      >
                        <Icon.ArrowButtonLeft />
                      </button>

                      <button
                        type="button"
                        css={[
                          css`
                            @media not all and (pointer: coarse) {
                              &:hover {
                                background: ${theme`colors.black`};

                                svg {
                                  polyline {
                                    stroke: ${theme`colors.white`};
                                  }
                                }
                              }
                            }
                          `,
                          tw`w-8 h-8 relative block ml-2 rounded-full overflow-hidden`
                        ]}
                        onClick={next}
                      >
                        <Icon.ArrowButtonRight />
                      </button>
                    </div>
                  </Grid>
                )}

                {screen && !isDesktop() && (
                  <div
                    css={[tw`w-full relative flex items-center justify-center`]}
                  >
                    <ul css={[tw`flex`]}>
                      {images.map((_, imageIndex) => {
                        const key = `image-carousel-swiper-bullet-${imageIndex}`;

                        return (
                          <li
                            key={key}
                            css={[
                              css`
                                background: ${current === imageIndex
                                  ? theme`colors.white`
                                  : `transparent`};
                              `,
                              tw`w-3 h-3 relative block rounded-full mx-1 border-white border`
                            ]}
                          />
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            )}
            slides={images.map((image, imageIndex) => {
              const key = `image-carousel-${imageIndex}`;

              return (
                <li key={key} css={[tw`w-full h-full relative`]}>
                  <Image
                    image={image}
                    inject={{
                      tw: tw`w-full h-full relative block object-cover`
                    }}
                  />
                </li>
              );
            })}
          />
        </section>
      )}
    </div>
  );
};

export default ImageCarouselSection;
