/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import useSanityShopifyProducts from "~hooks/useSanityShopifyProducts.jsx";
import ProductCard from "~components/ProductCard.jsx";

const CollectionSection = ({ data: { shopifyCollection } }) => {
  // ===========================================================================
  // context / ref / state

  const { isDesktop } = useContext(DocumentContext);
  const { allSanityShopifyProduct } = useSanityShopifyProducts();

  const products = [];

  if (allSanityShopifyProduct?.edges?.[0]) {
    allSanityShopifyProduct.edges.forEach(({ node }) => {
      if (!node?.collections?.[0]) {
        return;
      }

      node.collections.forEach(({ title }) => {
        if (title.toLowerCase() === shopifyCollection?.title.toLowerCase()) {
          products.push(node);
        }
      });
    });
  }

  // ===========================================================================
  // render

  return (
    <>
      {products?.[0] && (
        <section tw="w-full relative pt-8 md:pt-16 pb-12 md:pb-24">
          <Grid>
            <header css={[tw`col-span-12 mb-4`]}>
              <T.Heading
                css={[tw`whitespace-pre-wrap`]}
                font={isDesktop() ? `2` : `3`}
                level="2"
              >
                {shopifyCollection?.title || `Products`}
              </T.Heading>
            </header>
          </Grid>

          <Grid node="ul">
            {products.map((product) => {
              const availableSizes = [];

              if (product?.shopifyProduct?.variants?.[0]) {
                product.shopifyProduct.variants.forEach((variant) => {
                  variant.selectedOptions.forEach((selectedOption) => {
                    if (
                      selectedOption?.name.toLowerCase() === `size` &&
                      !availableSizes?.includes(selectedOption.value)
                    ) {
                      availableSizes.push(selectedOption.value);
                    }
                  });
                });
              }

              //

              return (
                <li
                  key={`collection-section-${product.handle}`}
                  css={[tw`col-span-12 md:col-span-6 relative mb-12`]}
                >
                  <ProductCard product={product} />
                </li>
              );
            })}
          </Grid>
        </section>
      )}
    </>
  );
};

export default CollectionSection;
