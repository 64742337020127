import React, { useContext } from "react";
import PropTypes from "prop-types";
import tw, { css } from "twin.macro";
import Plx from "react-plx";
import { DocumentContext } from "~context/DocumentContext.jsx";

const Parallaxer = ({ atTop, children, inject, reverse, visible }) => {
  const documentContext = useContext(DocumentContext);
  const { windowHeight } = documentContext;

  let startValue = windowHeight * 0.05;
  let endValue = windowHeight * -0.05;

  if (atTop) {
    startValue = 0;
    endValue = windowHeight * -0.1;
  }

  if (reverse) {
    endValue *= -2;
  }

  //

  return (
    <>
      {visible && (
        <Plx
          css={[
            css`
              ${inject?.css || ``}
            `,
            inject?.tw || tw``
          ]}
          parallaxData={[
            {
              start: `self`,
              duration: windowHeight * 2 || 1,
              properties: [
                {
                  startValue,
                  endValue,
                  property: `translateY`
                }
              ]
            }
          ]}
        >
          {children}
        </Plx>
      )}
    </>
  );
};

Parallaxer.defaultProps = {
  atTop: false,
  inject: null,
  reverse: false,
  visible: true
};

Parallaxer.propTypes = {
  atTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  inject: PropTypes.shape({
    css: PropTypes.string,
    tw: PropTypes.shape({})
  }),
  reverse: PropTypes.bool,
  visible: PropTypes.bool
};

export default Parallaxer;
