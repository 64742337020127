/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";

import CenterButtonSection from "~components/cms/sections/CenterButtonSection.jsx";
import CollectionSection from "~components/cms/sections/CollectionSection.jsx";
import GallerySection from "~components/cms/sections/GallerySection.jsx";
import FloatingImageSection from "~components/cms/sections/FloatingImageSection.jsx";
import ImageSection from "~components/cms/sections/ImageSection.jsx";
import ImageArticleSection from "~components/cms/sections/ImageArticleSection.jsx";
import ImageBannerSection from "~components/cms/sections/ImageBannerSection.jsx";
import ImageCarouselSection from "~components/cms/sections/ImageCarouselSection.jsx";
import InstagramSection from "~components/cms/sections/InstagramSection.jsx";
import OpenTextSection from "~components/cms/sections/OpenTextSection.jsx";
import ProductCarouselSection from "~components/cms/sections/ProductCarouselSection.jsx";
import TextSection from "~components/cms/sections/TextSection.jsx";

const PageBuilder = ({ pagebuilder }) => {
  // ===========================================================================
  // variables

  const { sections } = pagebuilder;

  const jsx = [];

  // ===========================================================================
  // JSX builder

  sections.forEach((section, sectionIndex) => {
    const sectionKey = `pagebuilder-section-${sectionIndex}`;

    let sectionJSX;

    switch (section._type) {
      case `centerButtonSection`:
        sectionJSX = <CenterButtonSection data={section} />;
        break;

      case `collectionSection`:
        sectionJSX = <CollectionSection data={section} />;
        break;

      case `floatingImageSection`:
        sectionJSX = <FloatingImageSection data={section} />;
        break;

      case `gallerySection`:
        sectionJSX = <GallerySection data={section} />;
        break;

      case `imageArticleSection`:
        sectionJSX = <ImageArticleSection data={section} />;
        break;

      case `imageBannerSection`:
        sectionJSX = <ImageBannerSection data={section} />;
        break;

      case `imageCarouselSection`:
        sectionJSX = <ImageCarouselSection data={section} />;
        break;

      case `imageSection`:
        sectionJSX = <ImageSection data={section} />;
        break;

      case `instagramSection`:
        sectionJSX = <InstagramSection data={section} />;
        break;

      case `openTextSection`:
        sectionJSX = <OpenTextSection data={section} />;
        break;

      case `productCarouselSection`:
        sectionJSX = <ProductCarouselSection data={section} />;
        break;

      case `textSection`:
        sectionJSX = <TextSection data={section} />;
        break;

      default:
        break;
    }

    jsx.push(
      <section
        key={sectionKey}
        className="page-builder__section"
        style={{
          backgroundColor: section?.backgroundColor?.hex || `white`,
          color: section?.fontColor?.hex || `black`
        }}
      >
        {sectionJSX}
      </section>
    );
  });

  return <div className="page-builder">{jsx}</div>;
};

export default PageBuilder;
