/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import Button from "~components/Button.jsx";
import Go from "~components/Go.jsx";

const CenterButtonSection = ({ data: { buttonLink } }) => (
  <section
    css={[tw`w-full relative pt-24 pb-24 flex items-center justify-center`]}
  >
    <Go
      to={buttonLink.buttonTarget}
      newTab={buttonLink?.buttonTarget?.includes(`http`)}
    >
      <Button
        color="black"
        inject={{ tw: tw`h-10` }}
        text={buttonLink.buttonText}
      />
    </Go>
  </section>
);
export default CenterButtonSection;
