/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import Image from "~components/Image.jsx";

const FloatingImageSection = ({ data: { imageLeft, imageRight } }) => {
  // ===========================================================================
  // context / ref / state

  const { isDesktop } = useContext(DocumentContext);

  // ===========================================================================
  // render

  return (
    <div css={[tw`relative pb-14 md:pb-40`]}>
      <Grid node="section">
        <figure
          css={[
            css`
              @media screen and (min-width: 1025px) {
                margin-top: 29vw;
              }
            `,
            tw`col-span-10 md:col-span-6`
          ]}
        >
          {imageLeft?.asset?.fluid && <Image image={imageLeft} />}
        </figure>

        <figure
          css={[
            tw`col-span-10 col-start-3 md:col-span-5 md:col-start-8 relative mt-12 md:mt-0`
          ]}
        >
          {imageRight?.asset?.fluid && <Image image={imageRight} />}
        </figure>
      </Grid>
    </div>
  );
};

export default FloatingImageSection;
