/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css } from "twin.macro";
import * as A from "~components/styles/Animations.jsx";
import Image from "~components/Image.jsx";
import Parallaxer from "~components/Parallaxer.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";

const ImageBannerSection = ({ data: { image, spinningSVG } }) => {
  // ===========================================================================
  // context / ref / state

  const { isDesktop, windowWidth } = useContext(DocumentContext);

  // ===========================================================================
  // variables

  let bannerHeight = isDesktop() ? `56.25vw` : `125vw`;

  if (windowWidth > 1600) {
    bannerHeight = `900px`;
  }

  // ===========================================================================
  // render

  return (
    <>
      {image?.asset?.fluid && (
        <section
          css={[
            css`
              ${A.Keyframes(`appear`, `1s ${A.EASING_CUBIC} forwards`, `0.3s`)}
              height: ${bannerHeight};
            `,
            tw`w-full relative flex items-center justify-center overflow-hidden`
          ]}
        >
          <Parallaxer
            atTop
            reverse
            severe
            inject={{ tw: tw`w-full h-full relative block` }}
          >
            <Image
              image={image}
              inject={{ tw: tw`w-full h-full object-cover` }}
            />

            {spinningSVG?.asset?.fluid?.src && (
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appearDown`,
                      `1s ${A.EASING_CUBIC} forwards`,
                      `0.5s`
                    )}
                  `,
                  tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center`
                ]}
              >
                <Image
                  image={spinningSVG}
                  inject={{
                    css: css`
                      ${A.Keyframes(`fullRotate`, `14s linear infinite`)}
                    `,
                    tw: tw`w-3/4 md:w-1/4 relative block`
                  }}
                />
              </div>
            )}
          </Parallaxer>
        </section>
      )}
    </>
  );
};

export default ImageBannerSection;
