/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import { DocumentContext } from "~context/DocumentContext.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import Button from "~components/Button.jsx";
import Go from "~components/Go.jsx";
import Image from "~components/Image.jsx";
import ProductCard from "~components/ProductCard.jsx";
import SwiperCarousel from "~components/SwiperCarousel.jsx";

const InstagramSection = ({ data: { button, heading, instagramPosts } }) => {
  const { isDesktop, screen } = useContext(DocumentContext);

  //

  return (
    <section
      css={[tw`w-full relative pt-10 md:pt-16 md:pt-24 pb-7 md:pb-10 bg-black`]}
    >
      <Grid node="header" inject={{ tw: tw`mb-12 md:mb-20` }}>
        <T.Heading
          font="3"
          level="3"
          inject={{ tw: tw`col-span-10 text-white` }}
        >
          {heading}
        </T.Heading>

        <div css={[tw`col-span-2 flex justify-end`]}>
          {button?.buttonTarget && (
            <Go to={button.buttonTarget} newTab>
              <Button
                color="white"
                inject={{ tw: tw`h-10` }}
                text={button.buttonText}
                transparent
              />
            </Go>
          )}
        </div>
      </Grid>

      {instagramPosts?.[0] && (
        <>
          {screen && isDesktop() && (
            <Grid node="ul">
              {instagramPosts.map((post, postIndex) => {
                const key = `instagram-grid-${postIndex}`;

                return (
                  <li
                    key={key}
                    css={[tw`col-span-6 md:col-span-4 relative mb-2 md:mb-0`]}
                  >
                    {post?.link && (
                      <Go to={post.link} newTab>
                        <div
                          css={[
                            css`
                              padding-bottom: 100%;
                            `,
                            tw`w-full relative overflow-hidden`
                          ]}
                        >
                          <figure
                            css={[
                              tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center overflow-hidden`
                            ]}
                          >
                            <Image
                              image={post.image}
                              inject={{
                                tw: tw`w-full h-full relative block object-cover`
                              }}
                            />
                          </figure>
                        </div>
                      </Go>
                    )}
                  </li>
                );
              })}
            </Grid>
          )}

          {screen && !isDesktop() && (
            <div css={[tw`w-screen relative px-3`]}>
              <SwiperCarousel
                options={{
                  slidesPerView: 2,
                  spaceBetween: 8
                }}
                nav={({ current }) => (
                  <>
                    <div
                      css={[
                        tw`w-full relative flex items-center justify-center mt-5`
                      ]}
                    >
                      <ul css={[tw`flex`]}>
                        {instagramPosts.map((_, postIndex) => {
                          const key = `instagram-swiper-bullet-${postIndex}`;

                          return (
                            <li
                              key={key}
                              css={[
                                css`
                                  background: ${current === postIndex
                                    ? theme`colors.white`
                                    : `transparent`};
                                `,
                                tw`w-3 h-3 relative block rounded-full mx-1 border-white border`
                              ]}
                            />
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
                slides={instagramPosts.map((post, postIndex) => {
                  const key = `instagram-grid-${postIndex}`;

                  return (
                    <li
                      key={key}
                      css={[tw`col-span-6 md:col-span-4 relative mb-2 md:mb-0`]}
                    >
                      {post?.link && (
                        <Go to={post.link} newTab>
                          <div
                            css={[
                              css`
                                padding-bottom: 100%;
                              `,
                              tw`w-full relative overflow-hidden`
                            ]}
                          >
                            <figure
                              css={[
                                tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center overflow-hidden`
                              ]}
                            >
                              <Image
                                image={post.image}
                                inject={{
                                  tw: tw`w-full h-full relative block object-cover`
                                }}
                              />
                            </figure>
                          </div>
                        </Go>
                      )}
                    </li>
                  );
                })}
              />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default InstagramSection;
